import React from "react"
import { Button } from "react-bootstrap"
import Img from "gatsby-image"
import { useStaticQuery, graphql, Link } from "gatsby"

const AdvContent = () => {
  const data = useStaticQuery(graphql`
    query {
      adv1: file(relativePath: { eq: "adv/adv1.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      adv2: file(relativePath: { eq: "adv/adv2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <div className="adv-title adv-foo">Smart Advertising</div>
      <div className="adv-top">
        <Img
          fluid={data.adv1.childImageSharp.fluid}
          alt=""
          className="adv-img adv-foo"
        />
        <div className="adv-box adv-foo">
          <div className="adv-box-in">
            <div className="adv-box-title adv-foo">
              Accurate placement of Google keywords
            </div>
            <div className="adv-box-text adv-foo">
              People overseas are accustomed to using Google before doing
              things. When users search for the products or services you
              provide, Google will accurately grasp such critical moments and
              display your ads in a timely manner. Regardless of using a desktop
              device or a mobile device, a timely advertisement can always
              greatly encourage the user to make a purchase.
            </div>
            <a href="mailto:bbaservice@network.com">
              <Button>Contact us</Button>
            </a>
          </div>
        </div>
        {/* 奇奇怪怪的 */}
        <span className="adv-temp1 adv-foo"></span>
        <span className="adv-temp2 adv-foo"></span>
      </div>
      {/* Facebook Advertising */}
      <div className="adv-title adv-foo">Facebook Advertising</div>
      <div className="adv-bottom">
        <Img
          fluid={data.adv2.childImageSharp.fluid}
          alt=""
          className="adv-img adv-foo"
        />
        <div className="adv-box adv-foo">
          <div className="adv-box-in">
            <div className="adv-box-title adv-foo">Facebook Advertising</div>
            <div className="adv-box-text adv-foo">
              Professional advertising technology and excellent marketing team
              can help you find audiences who are interested in the products.
              Facebook pixel enables you to make sure that your
              ads are shown to the right people and you can
              measure the results of your ads, which helps to drive more sales.
            </div>
            <a href="mailto:bbaservice@network.com">
              <Button>Contact us</Button>
            </a>
          </div>
        </div>
        {/* 奇奇怪怪的 */}
        <span className="adv-temp3 adv-foo"></span>
        <span className="adv-temp4 adv-foo"></span>
        <span className="adv-temp5 adv-foo"></span>
      </div>
    </>
  )
}

export default AdvContent
